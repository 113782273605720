class removeFields {
  // This executes when the function is instantiated.
  constructor() {
    this.iterateLinks()
  }

  iterateLinks() {
    // Use event delegation to ensure any fields added after the page loads are captured.
    document.addEventListener('click', e => {
      if (e.target && e.target.className.includes('remove_fields')) {
        this.handleClick(e.target, e)
      }
    })
  }

  handleClick(link, e) {
    // Stop the function from executing if a link or event were not passed into the function.
    if (!link || !e) return
    // Prevent the browser from following the URL.
    e.preventDefault()
    // Find the parent wrapper for the set of nested fields.
    let fieldParent = link.closest('.nested-fields')

    // If there is a parent wrapper, find the hidden delete field.
    let deleteField = fieldParent
      ? fieldParent.querySelector('input[type="hidden"]')
      : null
    // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
    if (deleteField) {
      deleteField.value = 1
      fieldParent.style.display = 'none'
    }

    /*
     * These lines will find select2 product-id dropdown in the deleted element and add
     * a dummy value. We need this to bypass the HTML validation.
     *
     * It's safe to do so because the row will be ignored on the backend anyway since we set
     * the hidden field as true above.
     */
    productSelect = fieldParent.querySelector('select[data-behavior="product-id-select2"]')
    if (productSelect) {
      var dummyOption = $("<option selected='selected'></option>").val(10)
      $(productSelect).append(dummyOption).trigger('change');
    }

    /*
     * If the nested field doesn't have ID then we just remove the whole line so we won't
     * create order with dummy product from above
     */
    nestedFieldId = fieldParent.querySelector('input[data-behavior="nested-field-id"]')
    if (nestedFieldId && nestedFieldId.value == "") {
      fieldParent.remove()
    }
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new removeFields())
