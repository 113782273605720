import flatpickr from 'flatpickr'
require("flatpickr/dist/flatpickr.css");

document.addEventListener('turbolinks:load', () => {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  });
})

document.addEventListener('turbolinks:before-cache', () => {
  const flatpickr = window.$('[data-behavior="flatpickr"]')[0]

  if(flatpickr) {
    flatpickr._flatpickr.destroy()
  }
});
