document.addEventListener('turbolinks:load', () => {
  window.$('*[data-behavior="customer-id-select2"]').select2({
    minimumInputLength: 1,
    placeholder: 'Select a customer',
    ajax: {
      url: '/customers/search'
    }
  })

  // Auto prefill shipping address when user choose a customer
  window.$('*[data-behavior="customer-id-select2"]').on('select2:select', function(e) {
    $('#order_shipping_address').val(e.params.data.text);
  })

  window.$('*[data-behavior="product-id-select2"]').select2({
    minimumInputLength: 1,
    placeholder: 'Select a product',
    ajax: {
      url: '/products/search'
    }
  })

  window.$('*[data-behavior="product-id-select2"]').on('select2:select', function(e) {
    $(e.target).closest(".nested-fields").find('[data-behavior="order-product-price"]').val(e.params.data.price);
  })
})

document.addEventListener('turbolinks:before-cache', () => {
  window.$('*[data-behavior="customer-id-select2"]').select2('destroy');
  window.$('*[data-behavior="product-id-select2"]').select2('destroy');
});
